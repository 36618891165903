@lightgrey: lightgrey;



.auth-bg-container {
    background: url(../../assets/images/glass-jar-full-money-front-decreasing-stacked-coins-against-white-background_1.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: calc(100vh - 130px);

    display: flex;
    justify-content: center;

    @media only screen and (min-width: 576px) {
        background-position-x: 40%;
    }

    @media only screen and (max-width: 768px) {
        background-position-x: 35%;
    }
}

.auth-container {
    margin: auto 0;
    box-shadow: 2px 2px 5px lightgray;
    background-color: white;
    display: flex;
    justify-content: center;

    .ant-select:not(.ant-select-customize-input) {
        .ant-select-selector {
            border: 1px solid @lightgrey;
        }

        .ant-select-arrow {
            color: black;
        }
    }

    .ant-input-affix-wrapper {
        border: 1px solid @lightgrey;
    }

    .request-btn {
        color: @lightgrey;
        background: #F5F5F5;
        border: 1px solid @lightgrey;
    }

    .cover-container {
        width: 100%;
        padding-top: 38px;
        margin: auto;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    @media only screen and (max-width: 576px) {
        max-width: 432px;
        margin: auto 20px;
    }

    @media only screen and (min-width: 576px) {
        width: 528px;
    }

}

.auth-content {
    padding: 24px;
}

.forgot-password-container {
    display: flex;
    justify-content: space-between;
    padding: 0 24px 12px 24px;
    text-align: right;


    button {
        color: @primary-color;
    }

    @media only screen and (max-width: 576px) {
        text-align: left;
        padding: 0 4px 4px 4px;
    }
}

.sms-same-row-container {
    display: flex;

    >div {
        flex: 1;
    }
}

.auth-steps-container {
    padding: 24px 24px 8px;

    @media only screen and (max-width: 576px) {
        padding: 12px 12px 8px;
        width: 320px;
    }
}

.auth-success-message {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 16px;
}

.auth-button {
    padding-top: 12px;

    Button {
        background-color: @btn-default-color;
        box-shadow: 2px 2px 5px lightgray;
    }

    @media only screen and (max-width: 576px) {
        padding-top: 8px;
    }
}

.auth-error {
    margin-bottom: 24px;
}

.phone-container {
    display: flex;
    justify-content: space-around;
    align-items: center;

}
@primary-color: #DDA000;@primary-1: #303030;@link-color: #EBB21B;@button-text: #EBB21B;@border-color: #DDA000;@btn-default-color: #DDA000;@gray: #8C8C8C;@border-color-base: #DDA000;