@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
  font-weight: lighter;
  font-style: italic;
}

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-1 {
  font-size: 34px;
  font-weight: 700;
}

.header-2 {
  font-size: 24px;
  font-weight: 700;
}

.font-subtitle1 {
  font-size: 16px;
  font-weight: 700;
}

.font-subtitle2 {
  font-size: 14px;
  font-weight: 700;
}

.font-body-1 {
  font-size: 24px;
}

.font-body-2 {
  font-size: 16px;
}

.font-caption {
  font-size: 14px;
}

.font-align-center {
  text-align: center;
}

html,
body,
#root,
.App {
  background: #f0f2f5;
  height: 100%;
}