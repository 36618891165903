.pinned-container {
    display: flex;
    justify-content: space-around;
    padding: 40px 0;
    background-color: white;

    @media only screen and (max-width: 768px) {
        padding: 12px 20px;
    }

    @media only screen and (max-width: 576px) {
        flex-direction: column;
        padding: 8px 12px;
    }
}

.pinned-content {
    max-width: 470px;

    .ant-carousel {
        border-radius: 8px;
        box-shadow: 5px gray !important;
        -webkit-box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.15) !important;
    }

    .slick-dots {
        position: inherit;
    }

    .slick-dots li {
        height: 0px;
    }

    .slick-dots li button {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: gray
    }

    .slick-dots li.slick-active button {
        width: 7px;
        height: 7px;
        border-radius: 100%;
        background: darkgrey;
    }

    @media only screen and (max-width: 768px) {
        max-width: 350px;
    }

    @media only screen and (max-width: 576px) {
        padding: 8px 8px 0 8px;
    }
}

.pinned-button {
    display: flex;
    margin-top: 40px;

    @media only screen and (max-width: 576px) {
        flex-direction: column;
    }

    Button {
        height: 44px;
        width: 250px;
        margin-right: 12px;
        font-weight: 700;

        @media only screen and (max-width: 576px) {
            width: 100%;
            margin: 8px 0;
        }
    }
}

.carousel-container {
    display: flex;
    justify-content: flex-start;

    @media only screen and (max-width: 768px) {

        .image-container {
            max-width: 150px;

            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }
    }

    @media only screen and (max-width: 576px) {

        .image-container {
            max-width: 150px;

            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }
    }

    .carousel-content {
        font-size: 12px;
        padding: 12px;

        @media only screen and (max-width: 768px) {
            max-width: 210px;
            padding: 8px;
        }

        @media only screen and (max-width: 576px) {
            max-width: 200px;
        }
    }

    .carousel-content-title {
        margin: 0 0 16px 0;

        @media only screen and (max-width: 576px) {
            margin: 0 8px 16px 8px;
        }
    }

    .carousel-content-subtitle {
        margin: 0 0 16px 0;

        @media only screen and (max-width: 576px) {
            margin: 0 8px 16px 8px;
        }
    }
}
@primary-color: #DDA000;@primary-1: #303030;@link-color: #EBB21B;@button-text: #EBB21B;@border-color: #DDA000;@btn-default-color: #DDA000;@gray: #8C8C8C;@border-color-base: #DDA000;