@border-color: lightgrey;

.userName {
    color: @button-text;
    margin: 0px 20px;
    padding: 0;
}

.site-layout-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background: @primary-1;

    >div {
        display: flex;
        padding: 0 24px;

        @media only screen and (max-width: 768px) {
            padding: 0;
        }

        .profile-menu {
            float: left;
            line-height: 35px;
            padding: 0 20px;

            .profile-menu-title {
                padding: 0 0 0 3px;
            }

        }
    }

    .header-container {
        width: 100%;
        max-width: 1280px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .header-actions {
        display: flex;
        align-items: center;
        background: @primary-1;

        .login-button {
            background-color: @primary-1;
            margin-left: 16px;
            border-color: @border-color;
        }

        .avatar {
            line-height: 32px !important;
            margin: 0 8px;
        }
    }

    .header-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 12px 0px;
    }

    .menu {
        max-width: 600px;

        @media only screen and (max-width: 768px) {
            max-width: 100px;
        }

        border-bottom: 0;
        background: none;

        .sub-menu {
            color: @button-text  !important;
            margin: 0 5px;
        }

        .sub-menu:hover {
            color: @button-text  !important;
            background: rgba(235, 178, 27, 0.2);
            border-radius: 4px;
        }

        .menu-item {
            margin: 0 5px;
            padding: 0 15px !important;
            color: @button-text  !important;
        }

        .menu-item a {
            color: @button-text  !important;
        }

        .menu-item:hover {
            color: @button-text  !important;
            background: rgba(235, 178, 27, 0.2);
            border-radius: 4px;
        }
    }

    .center-divider {
        height: 30px;
        background-color: rgba(235, 178, 27, 0.2);
        margin-right: 10px;
    }
}

.side-menu-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ant-drawer-body {
    padding: 4px;
}

.ant-drawer-header,
.ant-drawer-content {
    background: @primary-1;
    padding-bottom: 16px;
}

.ant-drawer-header {
    border-bottom: 0;
}

.ant-drawer-content {
    .side-menu {
        background: @primary-1;

        a {
            color: @primary-color;
        }

        .ant-menu-item-selected {
            background: @primary-color;

            a {
                color: @primary-1;
                font-weight: bolder;
            }

        }

    }
}

.ant-menu-vertical {
    border-right: 0;
}

.header-menu {
    margin-right: 0;

    .more-icon {
        color: @primary-color;

        &:hover {
            background-color: @primary-1;
            color: @primary-color;
        }

    }
}

.ant-menu-submenu {
    color: @button-text;
    background: @primary-1;
}

.ant-menu-submenu::after {
    content: none !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after {
    content: none !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
    padding: 0px;
}

.ant-menu-horizontal {
    line-height: 35px !important;
}

.ant-menu-submenu-title {
    padding: 0px 20px !important;
}

.ant-menu-submenu-title:hover {
    color: @button-text  !important;
}

.ant-menu-item-selected span,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
    color: @button-text;
}

.ant-layout-header {
    height: 80px;
}

.financing-button {

    background-color: #EBB21B33;
    border-radius: 4px;
    border: 0;

    li {
        background-color: transparent;
        border-radius: 4px;
        border: 0;
    }

    .icon {
        font-size: 12px !important;
        vertical-align: middle !important;
    }
}

.header-user-dropdown {
    >li {
        margin: 4px 0 8px;
        padding: 0 16px;
        line-height: 40px;
        &:hover {
            background-color: #FFFFFF;
        }

        &:first-child {
            &:hover {
                .ant-dropdown-menu-title-content {
                    color: #000000;
                }
            }
        }
        &:hover{
            .ant-dropdown-menu-title-content {
                color: @button-text;
            }
        }
    }
}
@primary-color: #DDA000;@primary-1: #303030;@link-color: #EBB21B;@button-text: #EBB21B;@border-color: #DDA000;@btn-default-color: #DDA000;@gray: #8C8C8C;@border-color-base: #DDA000;