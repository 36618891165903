.financing-form-container {
    max-width: 900px;
    width: 100%;
    padding: 24px 32px;

    @media only screen and (max-width: 576px) {
        padding: 24px 12px 4px 12px;
    }

    .form-title {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ant-picker {
        width: 100%;
    }

    .ant-input-number {
        width: 100%;
    }

    .form-button {

        .ant-form-item-control-input-content {
            display: flex;
            justify-content: center;
        }

        Button {
            height: 40px;

            @media only screen and (max-width: 576px) {
                margin: 24px 8px 8px 8px;
                width: 100%;
            }
        }

    }

    Button {
        margin: 12px 20px 12px 0;
    }
}

.otp-error {
    max-width: 350px;
}

.financing-component-container {
    align-items: center;
    padding: 0 25px 20px;

    @media only screen and (min-width: 992px) {
        padding: 0 15px 80px;

        .financing-component-information {
            min-width: 460px;
            max-width: 536px;
        }
    }

    .financing-col {
        display: grid;
    }
}

.financing-component-information {
    border: 1px solid @primary-color;
    background-color: #fff;
    padding: 12px 16px;
    border-radius: 8px;

    .financing-title {
        color: @primary-color;
        margin-bottom: 12px;
        line-height: 1.2;
    }
}

.financing-application-banner {
    min-height: calc(100vh - 130px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../assets/images/business-partners-shaking-hands-monochrome.png");
    display: flex;
    justify-content: center;
    align-items: center;

    .financing-application-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;

        .application-title {
            text-align: center;
        }

        @media only screen and (max-width: 576px) {
            padding: 12px 8px;
        }

        .fail-icon {
            color: red;
        }

        img,
        svg {
            margin: 16px 0;
            height: 100px;
            width: 100px;

            @media only screen and (max-width: 576px) {
                margin: 20px 0 20px 0;
            }
        }

        .application-details-container {
            display: flex;
            background-color: #fff;
            color: black;
            max-width: 600px;
            margin: 12px 0;
            padding: 12px 12px;
            justify-content: space-around;
            border-radius: 8px;

            div {
                flex-grow: 1;
            }

            @media only screen and (max-width: 576px) {
                max-width: 300px;
                margin: 12px 0;
                padding: 8px;
            }

            .application-details-column {
                display: flex;
                flex-direction: column;
                margin: 6px 20px;
                justify-content: center;

                @media only screen and (max-width: 576px) {
                    max-width: 200px;
                    margin: 4px 0 12px 0;
                }

                span {
                    padding: 20px 4px;
                }
            }
        }

        .application-button-container-fail {
            margin-top: 100px !important;
        }

        .application-button-container {
            display: flex;
            margin-top: 20px;
            width: 100%;
            justify-content: center;

            @media only screen and (max-width: 576px) {
                flex-direction: column;
            }

            Button {
                width: 250px;
                margin-right: 12px;
                font-weight: 700;
                height: 40px;

                @media only screen and (max-width: 576px) {
                    width: 100%;
                    margin: 8px 0;
                }
            }
        }
    }
}

.content-container.business-financing-container, .content-container.personal-financing-container {

    align-items: center;
    background-color: #f5f5f5;
    padding: 20px 15px;

    .financing-container-content {
        padding: 20px 15px;
        background-color: #f5f5f5;

        @media only screen and (max-width: 576px) {
            padding: 20px 12px;
            flex-direction: column;
        }

        .financing-component-list {

            @media only screen and (min-width: 992px) {
                padding-left: 15px;
            }
        }
    }
}
@primary-color: #DDA000;@primary-1: #303030;@link-color: #EBB21B;@button-text: #EBB21B;@border-color: #DDA000;@btn-default-color: #DDA000;@gray: #8C8C8C;@border-color-base: #DDA000;