.site-layout-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background: @primary-1;
    color: #fff;
    display: flex;
    padding: 12px 50px;
    font-size: 12px;

    @media only screen and (max-width: 640px) {
        height: 80px;
        padding: 8px 20px 16px 20px;
    }

    @media only screen and (max-width: 576px) {
        height: 80px;
        padding: 8px 20px 16px 20px;
    }

    @media only screen and (max-width: 320px) {
        height: 100px;
    }

    .footer-container {
        display: flex;
        width: 100%;
        max-width: 1280px;
        justify-content: space-between;
        padding: 0 12px;

        .footer-buttons {
            display: flex;
            align-items: center;
            justify-content: center;

            .divider-color {
                background-color: white;
                opacity: 40%;
            }
        }

        @media only screen and (min-width: 720px) {
            .main {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                max-width: 1280px;
                height: 40px;
                width: 70%;
            }
        }

        @media only screen and (max-width: 640px) {
            flex-direction: column;
            height: 80px;
            justify-content: center;

            .main {
                text-align: center;
                font-size:11px;
            }

            .footer-buttons {
                margin-top: 0px;
            }
        }

        @media only screen and (max-width: 576px) {
            flex-direction: column;
            height: 80px;
            justify-content: center;

            .main {
                text-align: center;
                font-size:11px;
            }

            .footer-buttons {
                margin-top: 0px;
            }
        }

    }
}

.button-text {
    color: white;
    padding: 0px;
    font-size: 12px;

    @media only screen and (max-width: 576px) {
        font-size: 9px;
    }
}

.button-text:hover {
    color: white;
}
@primary-color: #DDA000;@primary-1: #303030;@link-color: #EBB21B;@button-text: #EBB21B;@border-color: #DDA000;@btn-default-color: #DDA000;@gray: #8C8C8C;@border-color-base: #DDA000;