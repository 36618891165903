.contact-us-container {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	padding: 50px 60px;

	@media only screen and (max-width: 768px) {
		flex-direction: column;
	}

	@media only screen and (max-width: 576px) {
		padding: 28px 20px;
	}

	.contact-us {
		max-width: 440px;
		width: 100%;

		@media only screen and (max-width: 768px) {
			margin-bottom: 36px;
		}

		@media only screen and (max-width: 576px) {
			margin-bottom: 16px;
		}

		.contact-us-information {
			margin-top: 36px;

			@media only screen and (max-width: 576px) {
				margin-top: 16px;
			}

			>div {
				display: flex;
				flex-direction: column;
				padding-bottom: 20px;
				width: 360px;

				@media only screen and (max-width: 576px) {
					width: auto;

					&:last-child {
						padding-bottom: 12px;
					}
				}

			}
		}
	}

	.contact-us-form {
		max-width: 840px;
		width: 100%;

		.ant-form-item-label>label {
			width: 130px;
		}

		.send-message-button {
			display: flex !important;
			justify-content: center !important;
		}

		Button {
			width: 200px;
			margin-top: 12px;
			color: #fff;
			background: @button-text;

			@media only screen and (max-width: 768px) {
				width: 100%;
			}
		}

		TextArea {
			resize: none;
		}

	}
}
@primary-color: #DDA000;@primary-1: #303030;@link-color: #EBB21B;@button-text: #EBB21B;@border-color: #DDA000;@btn-default-color: #DDA000;@gray: #8C8C8C;@border-color-base: #DDA000;