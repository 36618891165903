.contact-us-banner {
	width: 100%;
	height: 340px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url('../../assets/images/contact-us-banner.png');
	span {
		&:first-child {
			margin-bottom: 16px;
		}
	}
}
@primary-color: #DDA000;@primary-1: #303030;@link-color: #EBB21B;@button-text: #EBB21B;@border-color: #DDA000;@btn-default-color: #DDA000;@gray: #8C8C8C;@border-color-base: #DDA000;