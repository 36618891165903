.language-switcher-container {
    display: flex;
    align-items: center;
}

.language-switcher-wrapper {
    .language-switcher-divider {
        margin: 0;
        background-color: #d9d9d9;
    }

    &:last-child {
        .language-switcher-divider {
            background-color: transparent;
        }
    }
}

.language-selection {
    .ant-select-selector {
        background-color: #EBB21B33 !important;
        color: @primary-color !important;
        border: 0 !important;
    }
}

.ant-select-item-option-active {
    color: @primary-color !important;
}

.ant-select-arrow {
    color: @primary-color;
}

.selected-language {
    font-size: 16px;
    font-weight: 900;
}

@primary-color: #DDA000;@primary-1: #303030;@link-color: #EBB21B;@button-text: #EBB21B;@border-color: #DDA000;@btn-default-color: #DDA000;@gray: #8C8C8C;@border-color-base: #DDA000;