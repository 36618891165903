.statistic-container {
    display: flex;
    padding: 40px 0;
    justify-content: space-around;

    @media only screen and (max-width: 576px) {
        padding: 12px 0;
        flex-direction: column;
        align-items: center;
    }
}

.statistic-content {
    display: flex;
    flex-direction: column;

    .statistic-title {
        font-size: 24px;
        font-weight: 400;
        color: @gray;

        @media only screen and (max-width: 576px) {
            padding: 12px 0;
            display: flex;
            justify-content: center;
        }
    }

    .statistic-values {
        font-size: 48px;
        font-weight: 400;

        @media only screen and (max-width: 576px) {
            padding: 12px 0;
            display: flex;
            justify-content: center;
        }
    }
}
@primary-color: #DDA000;@primary-1: #303030;@link-color: #EBB21B;@button-text: #EBB21B;@border-color: #DDA000;@btn-default-color: #DDA000;@gray: #8C8C8C;@border-color-base: #DDA000;