.tnc-container {
    padding: 20px;

    .tnc-title {
        padding: 12px 0;
    }

    .tnc-table {
        display: flex;
        flex-direction: column;


        .tnc-table-row {
            display: flex;

            .tnc-table-column {
                display: flex;
                flex-direction: column;
                margin: 0 20px;

                span {
                    margin: 0 0 20px 0;
                }

                .tnc-italic {
                    font-style: italic;
                }
            }
        }
    }
}
@primary-color: #DDA000;@primary-1: #303030;@link-color: #EBB21B;@button-text: #EBB21B;@border-color: #DDA000;@btn-default-color: #DDA000;@gray: #8C8C8C;@border-color-base: #DDA000;