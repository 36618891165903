.about-us-banner,
.about-us-container,
.about-us-info-1 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

}

.about-us-banner,
.about-us-our-values {
	padding: 50px;

	@media only screen and (max-width: 576px) {
		padding: 28px 20px;
	}
}

.about-us-banner {
	width: 100%;
	height: 340px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url('../../assets/images/about-us-banner.png');

	span {
		&:first-child {
			margin-bottom: 16px;
		}
	}
}

.about-us-container {
	padding: 50px;

	@media only screen and (max-width: 576px) {
		padding: 28px 20px;
	}
}

.about-us-info-1 {
	span {
		&:first-child {
			margin-bottom: 16px;
		}
	}
}

.about-us-info-2 {
	display: flex;

	flex-direction: row;
	justify-content: space-evenly;
	margin-top: 40px;

	@media only screen and (max-width: 576px) {
		flex-direction: column;
	}

	>div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@media only screen and (max-width: 576px) {
			margin-bottom: 20px;
		}
	}
}

.about-us-info-3 {
	display: flex;
	flex-direction: column;
	padding: 50px 50px 0 50px;

	@media only screen and (max-width: 576px) {
		padding: 28px 20px 0 20px;
	}

	align-items: center;

	.mission-vision {
		display: flex;
		flex-direction: row;
	}

	.without-number {
		li:only-of-type {
			list-style-type: none;
		}
	}
}

.bg-grey {
	background: #F5F5F5;

	.about-us-our-values {
		max-width: 1280px;
		margin: auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.our-value-title {
			text-align: center;
		}

		.our-values-details {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-top: 28px;

			@media only screen and (max-width: 576px) {
				flex-direction: column;
			}

			>div {
				display: flex;
				flex-direction: column;
				max-width: 380px;
				padding: 12px 20px;

				>div {
					&:first-child {
						color: @primary-color;
						margin-bottom: 12px;
					}
				}
			}
		}
	}

}
@primary-color: #DDA000;@primary-1: #303030;@link-color: #EBB21B;@button-text: #EBB21B;@border-color: #DDA000;@btn-default-color: #DDA000;@gray: #8C8C8C;@border-color-base: #DDA000;