.country-selector {
    width: 100px !important;
}

.country-dropdown {
    min-width: 300px !important;

    .country-dropdown-item {
        >div {
            display: flex;
            align-items: center;
        }

        img {
            width: 24px;
            margin-right: 8px;
        }
    }
}

.country-selected-title {
    display: flex;
    align-items: center;

    img {
        width: 24px;
        margin-right: 8px;
    }
}
@primary-color: #DDA000;@primary-1: #303030;@link-color: #EBB21B;@button-text: #EBB21B;@border-color: #DDA000;@btn-default-color: #DDA000;@gray: #8C8C8C;@border-color-base: #DDA000;