.loan-calculator {
    .ant-input-number {
        width: 100%;
    }

    .ant-btn {
        width: 100%;
        height: 48px;
    }
}

.monthly-payment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .result {
        font-size: 24px;
    }
}

.ant-input-number-handler-wrap { 
    visibility: hidden;
}
@primary-color: #DDA000;@primary-1: #303030;@link-color: #EBB21B;@button-text: #EBB21B;@border-color: #DDA000;@btn-default-color: #DDA000;@gray: #8C8C8C;@border-color-base: #DDA000;