.home-about-us-container {
    background-color: white;
    display: flex;
    padding: 40px 0;
    justify-content: space-around;
    flex-direction: row;

    @media only screen and (max-width: 768px) {
        padding: 16px 20px;
    }

    @media only screen and (max-width: 576px) {
        padding: 12px 4px;
        flex-direction: column;
    }
}


.home-about-us-content {
    max-width: 400px;
    margin: 0 8px;

    @media only screen and (max-width: 768px) {
        max-width: 350px;
    }

    .home-about-us-title {
        margin-bottom: 40px;

        @media only screen and (max-width: 768px) {
            margin-bottom: 50px;
            padding: 0 8px;
        }
    }
}

.home-about-us-subcontent {
    max-width: 600px;

    @media only screen and (max-width: 576px) {
        padding: 0 8px;
    }

    div {
        padding: 2px 0;
        width: 300px;

        @media only screen and (max-width: 768px) {
            width: 200px;
        }

        @media only screen and (max-width: 576px) {
            width: 150px;
        }
    }

    .home-about-us-subcontent-details {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .home-about-us-subtitle {
        font-size: 30px;
        font-weight: 700;
    }
}

.home-about-us-button {
    display: flex;
    margin-top: 50px;

    @media only screen and (max-width: 576px) {
        margin: 16px 0;
    }

    Button {
        margin-right: 12px;
        height: 44px;
        width: 200px;
        font-weight: 700;
    }
}
@primary-color: #DDA000;@primary-1: #303030;@link-color: #EBB21B;@button-text: #EBB21B;@border-color: #DDA000;@btn-default-color: #DDA000;@gray: #8C8C8C;@border-color-base: #DDA000;