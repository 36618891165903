.financing-container {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;

    @media only screen and (max-width: 768px) {
        padding: 12px;
    }

    @media only screen and (max-width: 576px) {
        padding: 12px;
    }
}

.financing-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 8px;
}

.financing-container-title {
    margin: 0 0 32px 0;
}

.financing-content-subtitle {
    margin: 0 0 16px 0;
}

.financing-container-subtitle {
    margin: 0 0 32px 0;

    @media only screen and (max-width: 576px) {
        margin: 0 0 12px 0;
    }
}

.financing-content-container {
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 768px) {
        padding: 8px;
    }

    @media only screen and (max-width: 576px) {
        padding: 8px;
        flex-direction: column;
        align-items: center;
    }
}

.financing-content {
    width: 500px;
    border: 1px solid @primary-color;
    padding: 24px 22px;
    border-radius: 8px;
    background-color: #fff;
    margin: 0 24px;

    @media only screen and (max-width: 768px) {
        max-width: 350px;
    }

    @media only screen and (max-width: 576px) {
        max-width: 300px;
        margin: 4px 0;
    }
}

.theme-text-color {
    color: @primary-color;
}
@primary-color: #DDA000;@primary-1: #303030;@link-color: #EBB21B;@button-text: #EBB21B;@border-color: #DDA000;@btn-default-color: #DDA000;@gray: #8C8C8C;@border-color-base: #DDA000;