.business-financing-banner {
    width: 100%;
    min-height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../assets/images/businessLoanImage.png");
    display: flex;
    flex-direction: column;
    align-items: center;
}

.business-financing-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 25px;

    .header-1 {
        line-height: 1.2;
    }

    @media only screen and (max-width: 576px) {
        align-items: flex-start;
    }
}

.business-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 12px;
}

.business-steps-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 12px 32px;
    padding: 12px 32px;

    @media only screen and (max-width: 576px) {
        margin: 12px 4px;
        padding: 12px 4px;
    }

    .ant-steps-item-process {
        .ant-steps-item-title::after {
            background-color: #b1b1b1 !important;
        }
    }
}
@primary-color: #DDA000;@primary-1: #303030;@link-color: #EBB21B;@button-text: #EBB21B;@border-color: #DDA000;@btn-default-color: #DDA000;@gray: #8C8C8C;@border-color-base: #DDA000;